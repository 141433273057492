import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
  oauthRequestApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { pagerdutyApiRef } from '@internal/backstage-plugin-test/src/routes';
import { PagerdutyBackendClient } from '@internal/backstage-plugin-test/src/api/PagerdutyBackendClient';
import { visitsApiRef, VisitsStorageApi } from '@backstage/plugin-home';
import { OAuth2 } from '@backstage/core-app-api';

import { BackstageInsights } from '@spotify/backstage-plugin-analytics-module-insights';

// DUO SSO 
export const duoOIDCAuthApiRef: ApiRef<
  OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'auth.duo.oidc',
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),

  // Pagerduty test plugin
  createApiFactory({
    api: pagerdutyApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef
    },
    factory: ({ discoveryApi, fetchApi }) => {
      return new PagerdutyBackendClient(
        {
          discoveryApi,
          fetchApi
        }
      );
    },
  }),

  // Visit api to track user traffic
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),

  // DUO SSO auth module api
  createApiFactory({
      api: duoOIDCAuthApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        oauthRequestApi: oauthRequestApiRef,
        configApi: configApiRef,
      },
      factory: ({ configApi, discoveryApi, oauthRequestApi }) =>
        OAuth2.create({
          configApi,
          discoveryApi,
          environment: configApi.getOptionalString('auth.environment'),
          defaultScopes: ['openid', 'profile', 'email', 'offline_access'],
          oauthRequestApi,
          popupOptions: { size: { fullscreen: false } },
          provider: { id: 'duo', title: 'DUO SSO', icon: () => null },
        }),
    }),

  BackstageInsights.createDefaultApiFactory(),
];
