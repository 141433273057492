import { Header } from "@backstage/core-components";
import { ClockConfig, HeaderWorldClock, WelcomeTitle } from "@backstage/plugin-home";
import React from "react";

export const WorldClockHeader = () => {
    const clockConfigs: ClockConfig[] = [
        {
            label: 'US/PST',
            timeZone: 'America/Los_Angeles',
        },
        {
            label: 'US/CST',
            timeZone: 'America/Chicago',
        },
        {
            label: 'US/EST',
            timeZone: 'America/New_York',
        },
        {
            label: 'LON',
            timeZone: 'Europe/London'
        },
        // {
        //     label: 'UTC',
        //     timeZone: 'UTC',
        // },
        {
            label: 'CET',
            timeZone: 'Europe/Budapest',
        },
        {
            label: 'ISR',
            timeZone: 'Asia/Jerusalem',
        },
    ];

    const timeFormat: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    return (
        <Header
      title={<WelcomeTitle language={['English']} />} pageTitleOverride="Home">
            <HeaderWorldClock
                clockConfigs={clockConfigs}
                customTimeFormat={timeFormat}
            />
        </Header>
    );
};