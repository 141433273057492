import React from 'react';
import {
    HomePageRandomJoke,
    HomePageStarredEntities,
    CustomHomepageGrid,
    FeaturedDocsCard,
    HomePageToolkit,
    TemplateBackstageLogoIcon,
    HomePageRecentlyVisited,
    LayoutConfiguration,
    HomePageCompanyLogo,
    TemplateBackstageLogo,
    // homePlugin
  } from '@backstage/plugin-home';

// import 
// {createCardExtension}
// from '@backstage/plugin-home-react'
//   import { Content, Header, Page } from '@backstage/core-components';
  import { HomePageSearchBar } from '@backstage/plugin-search';
import { WorldClockHeader } from './WorldClockHeader';
import { Grid, makeStyles } from '@material-ui/core';
// import { Page } from '@backstage/core-components';

//   import { HomePageCalendar } from '@backstage/plugin-gcalendar';
//   import { MicrosoftCalendarCard } from '@backstage/plugin-microsoft-calendar';

import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';


const useLogoStyles = makeStyles(theme => ({
      container: {
        margin: theme.spacing(5, 0),
      },
      svg: {
        width: 'auto',
        height: 100,
      },
      path: {
        fill: '#7df3e1',
      },
    }));

const useStyles = makeStyles(theme => ({
        searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
        },
        searchBarOutline: {
        borderStyle: 'none'
        }
    }));
  
  export const HomePage = () => { 
    const classes = useStyles();
    const { svg, path, container } = useLogoStyles();
    
    const defaultConfig : LayoutConfiguration[] = [
        {
          component: 'HomePageSearchBar',
          x: 0,
          y: 0,
          width: 12,
          height: 2,
        },
        {
          component: 'HomePageRandomJoke',
          x: 0,
          y: 2,
          width: 6,
          height: 4,
          resizable: true
        },
        {
          component: 'HomePageStarredEntities',
          x: 6,
          y: 2,
          width: 6,
          height: 4,
        },
        {
            component: "HomePageRecentlyVisited",
            x: 0,
            y: 6,
            width: 6,
            height: 4,
        },
        {
            component: "HomePageToolkit",
            x: 6,
            y: 6,
            width: 6,
            height: 4,
        }
      ];
    
    return (
    <>
    <WorldClockHeader/>
    <Grid container justifyContent='center'>
        <HomePageCompanyLogo
            className={container}
            logo={<TemplateBackstageLogo classes={{svg, path}} />}
        />
    </Grid>
    <CustomHomepageGrid config={defaultConfig} >
      <HomePageSearchBar InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline } }}/>
      <HomePageRandomJoke />
      {/* <HomePageCalendar />
      <MicrosoftCalendarCard /> */}
      <HomePageStarredEntities />
      <FeaturedDocsCard
        filter={{
          'spec.type': 'documentation',
          'metadata.name': 'getting-started-with-backstage',
        }}
      />
      <HomePageToolkit
        tools={Array(6).fill({
          url: '#',
          label: 'link',
          icon: <TemplateBackstageLogoIcon />,
        })}
      />
      <HomePageRecentlyVisited
        kind="recent"
        // numVisitsOpen={5}
        // numVisitsTotal={6}
        // loading
      />
      <HomePageRequestedReviewsCard/>
      <HomePageYourOpenPullRequestsCard 
      // default `query` prop = 'is:open is:pr author:@me archived:false'
      />
    </CustomHomepageGrid>
    </>
  )};


// const TestThing = homePlugin.provide(
//     createCardExtension<{query?: string | undefined}>({
//       name: 'Test thing',
//       title: 'THING TEST',
//       components: () => import('./components/Thing'),
//       description: 'this is a test thing',
//       layout: {
//         height: { minRows: 4 },
//         width: { minColumns: 3 },
//       },
//       settings: {
//         schema: {
//           title: 'Thing settings',
//           type: 'object',
//           properties: {
//             query: {
//               title: 'Query',
//               type: 'string',
//               default: 'org:RoadieHQ is:pr language:CSS',
//             },
//           },
//         },
//       },
//     }),
//   );