import { DiscoveryApi, FetchApi } from "@backstage/core-plugin-api";
import { PagerDutyBackendAPI, Service } from "../types";

export class PagerdutyBackendClient implements PagerDutyBackendAPI {
    private readonly discoverApi : DiscoveryApi;
    private readonly fetchApi : FetchApi;

    constructor(options : {discoveryApi : DiscoveryApi, fetchApi : FetchApi}) {
        this.discoverApi = options.discoveryApi;
        this.fetchApi = options.fetchApi
    }
    
    private async handleResponse(response : Response) : Promise<any> {
        if (!response.ok){
            throw new Error(response.statusText);
        }
        return await response.json()
    }

    async getService(serviceId: string): Promise<Service> {
        const url = `${await this.discoverApi.getBaseUrl('test-backend')}/pagerduty-service/${serviceId}`
        const response = await this.fetchApi.fetch(url, {
            method: 'GET'
        });
        return await this.handleResponse(response);
    }
}